
@media only screen and (orientation: landscape) {
  .player-container{
    display: flex ;
    flex-flow: row nowrap;
    justify-content: space-around;
    height: 100%;
  }
  .player-side-buttons-start {
    position: fixed;
    top: 50%;
    left : 0;
    width: 2vw;
    height: 4vw;
  }
  .player-side-buttons-end {
    position: fixed;
    top: 50%;
    left : 69%;
    width: 2vw;
    height: 4vw;
  }
  .player {
    flex: 0 0 95%;
    margin-top: 0.25vh;
  }
}

@media only screen and (orientation: portrait) {
  .player-container{
    display: flex ;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .player-side-buttons-start {
    flex: 0 0 20%;
    height: 5vmin;
    order: 1;
  }
  .player-side-buttons-end {
    flex: 0 0 20%;
    height: 5vmin;
    order: 2;
  }
  .player {
    flex: 0 0 98%;
    margin: 1%;
    order: 3;
  }
}