@import "../../node_modules/@blueprintjs/core/lib/scss/variables";

body{
  z-index: -1;
}
.body-dark {
  background-color: $pt-dark-app-background-color;
  color: $pt-dark-text-color;
}
.dark-back {
  background-color: $dark-gray5 !important;;
  color: $white !important;;
}
.light-back{
  background-color: $white !important;
  color: $dark-gray1 !important;;
}
.middle-back{
  background-color: $light-gray3!important;
  color: $black !important;;
}
.App {
  text-align: center;
}
.base-width{
  max-width: 98vw;
  margin: 0 auto;
}

.base-margin{
  margin: 1vmin auto;
}
.common-margin{
  margin: 1vmin;
}
.horizontal-margin {
  margin: 0 1vmin;
}
.tiny-margin{
  margin: 0.2vmin;
}
.margin-end{
  margin-right: 0.1vw;
}
.auto-margin{
  margin: auto;
}
.vertical-margin-center {
  width: 100%;
  margin: 0.25vmin auto;
}
.color-primary{
  color: $pt-intent-primary;
}

.color-success{
  color: $pt-intent-success;
}

.color-warning{
  color: $pt-intent-warning;
}

.color-danger{
  color: $pt-intent-danger;
}

.color-text-primary{
  color: $blue4;
}

.color-text-success{
  color: $green4;
}

.color-text-warning{
  color: $orange4;
}

.color-text-danger{
  color: $red4;
}

.error{
  background: $pt-intent-danger;
  color: white;
  margin: 2vmin;
}
.error-padding{
  background: $pt-intent-danger;
  color: white;
  padding: 1vmin;
  margin: 1vmin;
  border-radius: 0.5vmin;
}

.info{
  background: $pt-intent-primary;
  color: white;
  margin: 2vmin 0;
}

.success{
  background: $pt-intent-success;
  color: white;
  margin: 2vmin;
}

.success-padding{
  background: $pt-intent-success;
  color: white;
  padding: 1vmin;
  margin: 1vmin;
  border-radius: 0.5vmin;
}
$color-dark-blue-text : #022852;

$color-night-blue : #004079;

$color-night-blue-light : #3b659b;

.night{
  color :white;
  background: $color-night-blue-light;
}

.round{
  border-radius: 2vw;
}

.round-bottom{
  border-radius: 0 0 1.5vw 1.5vw;
}

$color-light-moon : #64D5D9;
.moon{
  background: $color-light-moon;
}


$x-large-text : 3vmin;
$large-text-landscape : 1vw;
$large-text-portrait : 3vw;

.color-dark-blue-text{
  color: $color-dark-blue-text;
}

@media only screen and (orientation: landscape) {
  .login-area{
    font-weight: 500;
    font-size: $large-text-landscape;
  }
}
@media only screen and (orientation: portrait) {
  .login-area{
    padding: 1.6em 1em 1em 1em;
    border-radius: 0 0 50% 50%;
    font-weight: 500;
    font-size: $large-text-portrait;
  }
}

.language-name{
  line-height: 100%;
  height: 100%;
  text-align:  center;
}

.login-buttons{
  top: 0;
}

.text-button:hover, .text-button:active{
  text-decoration: underline;
  cursor: pointer;
}

.faded-out {
  visibility: hidden;
  opacity: 0.6;
  transition: visibility 0s 5s, opacity 5s linear;
}
.faded-in{
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}
.card-caption {
  width: 80%;
  margin: 0.1vmin auto;
  flex: 1 1 80%;
  text-align: center; }

.card-caption-button {
  width: 3vmin;
  height: 3vmin;
  margin: 0.1vmin auto;
  flex: 0 0 3vmin;
  transform: translate(150%,-50%);
}

.block{
  display: block;
}
.wrap-text {
  word-break: break-all;
}
.text-wrapper{
  display: block;
  width: 100%;
}
.fill-width{
  width: 100%;
}

.fill-height{
  height: 100%;
}

.inline{
  display: inline-block;
}
.scroll-y{
  overflow-x: hidden;
  overflow-y: auto;
}
.select-popover{
  max-height: 99vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}
.details-popover{
  max-height: 99vh;
  width: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0.5vh;
}
.vertical-aligned-text--container {
  line-height: 200px;
  text-align: center;
}
.vertical-aligned-text {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.vertical-centered--container{
  position: relative;
  height: 100%;
  width: 100%;
}
.vertical-centered{
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0,-100%);
}
.centered-text{
  text-align: center;
}
.link-dark, a.link-dark :visited, a.link-dark :link{
  color: white;
}