.back-container{
  position: relative;
  min-height: 120vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.back-top{
  position: absolute;
  left: 0;
  top : 0;
  width : 100%;
  z-index: -1;
}
.back-top-right {
  position: absolute;
  right: 20%;
  top: 0;
  z-index: -1;
}
.back-middle{
  position: absolute;
  left: 0;
  top : 13vh;
  width : 100%;
}
@media (min-width:1023px) {
  .back-middle{
    position: absolute;
    left: 0;
    top : 33vh;
    width : 100%;
  }
  .product-download{
    position: fixed;
    left: 0;
    top: 50vh;
    width: 20vw;
    transform: translate(10%, -50%);
  }
  .language-panel{
    position: absolute;
    left: 50vw;
    top: -15vh;
    transform: translate(-50%, 0);
  }
}
.search--with-language{
  margin: 0 2vw;
  font-weight: bold;
}
.back-bottom{
  position: fixed;
  left: 0;
  top : 100%;
  width : 100%;
  z-index: -1;
  transform: translate(0,-95%);
}
/*
  foreground
 */
.foreground-top-right {
  position: absolute;
  right: 20%;
  white-space: nowrap;
  overflow-x: visible;
  overflow-y: hidden;
  min-width: 17%;
  background: $color-night-blue-light;
  top: 0;
  text-align: right;
}
.card-top-right {
  position: absolute;
  right: 22.5%;
  white-space: nowrap;
  overflow-x: visible;
  overflow-y: hidden;
  top: 0;
}
/*
  art gui
 */
.login-shape {
  fill: #69c3ff;
  fill-rule: evenodd;
  opacity: 1;
}

.product-card{
  background: $color-light-moon;
  border-radius: 1vw 1vw 5vw 5vw;
}

.product-expand{
  width: 50%;
  margin-top: 1vh;
  margin-left: 25%;
  margin-bottom: 1vh;
}

a:link {
  color: $color-dark-blue-text;
}


a:visited {
  color: $color-dark-blue-text;
}


a:hover {
  color: $color-dark-blue-text;
}


a:active {
  color: $color-dark-blue-text;
}