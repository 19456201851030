.message-red{
  color: $dark-gray5;
  font-weight: lighter;
  vertical-align: center;
}
.message-new{
  color: black;
  font-weight: bold;
  vertical-align: center;
}
.message-review{
  color: #15B371;
  font-weight: bold;
  vertical-align: center;
}