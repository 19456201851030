@media only screen and (orientation: landscape) {
  .sidebar-layout{
    display: flex ;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100vw;
    //height: 91vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 auto;
  }
  .sidebar-layout--inner{
    display: flex ;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100vw;
    overflow-x: hidden;
    margin: 1vmin 0 0 0;
  }
  .sidebar{
    flex: 0 0 28%;
    height: 100%;
    margin-left: 0;
    padding-right: 1vw;
  }
  .sidebar-content{
    flex: 0 0 70%;
    height: 100%;
  }
  .sidebar-bottom {
    position: fixed;
    top: 100%;
    transform: translate(0,-4.5vh);
    width: 100%;
    margin: 0;
    height: 4.5vh;
  }
  .sidebar-inner {
    width: 100%;
    margin: 0;
    height: 4.5vh;
  }
  .tiles-container-messages {
    display: flex ;
    flex-flow: column nowrap;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    max-height: 75vh;
  }
}
@media only screen and (orientation: portrait) {
  .sidebar-layout{
    display: flex ;
    flex-flow: column nowrap;
    justify-content: space-around;
    width: 100vw;
    //height: 90vh;
    overflow-y: hidden;
    overflow-x: hidden;
    margin: 0 auto;
  }
  .sidebar-layout--inner{
    display: flex ;
    flex-flow: column nowrap;
    justify-content: space-around;
    width: 100vw;
    overflow-x: hidden;
    margin: 1vmin 0 0 0;
  }
  .sidebar-content{
    flex: 0 0 40%;
    width: 100%;
  }
  .sidebar{
    flex: 0 0 50%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .sidebar-bottom--heading{
    display: none;
  }
  .sidebar-bottom {
    position: fixed;
    top: 100%;
    transform: translate(0,-100%);
    width: 100%;
    margin: 0;
    height: 6vh;
  }
  .sidebar-inner {
    width: 100%;
    margin: 0;
    height: 6vh;
  }
  .tiles-container-messages {
    display: flex ;
    flex-flow: column nowrap;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    max-height: 33vh;
  }
}
.navigation-text {
  text-overflow: ellipsis;
  word-break: break-all;
  overflow-wrap: break-word;
  hyphens: auto;
}
.navigation-text-wrapper{
  display: block;
  width: 100%;
}
