.form-field{
  margin: 1vmin auto;
}
.form-field--caption{
  margin-bottom: 0.5vmin;
}
.form-button{
  margin: 1vmin;
}

.form--item-container {
  display: flex ;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 1vmin !important;
}
.form--item-container--center {
  display: flex ;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 1vmin !important;
  margin: 0 auto;
}
.form--item-container--columns{
  display: flex ;
  flex-flow: column wrap;
  justify-content: space-around;
  padding: 1vmin !important;
}
.form-item-element{
  flex: 0 0 45%;
}
.form-item-row{
  margin: auto;
  flex: 0 0 95%;
}
.form-item-full-row{
  margin: auto;
  flex: 0 0 100%;
}
.full-row{
  flex: 0 0 100%;
}
.form-item-line{
  margin: 0 0 0.3vmin 0;
  flex: 0 0 100%;
}
.form--button-container{
  display: flex ;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.form--center-button-container{
  display: flex ;
  flex-flow: row nowrap;
  justify-content: center;
}
.form-button:nth-child(1),
.form-button:nth-child(3){
  flex: 0 0 15%;
}

.form-button:nth-child(2){
  flex: 1 1 50%;
}


.form-button-4:nth-child(1){
  flex: 0 0 15%;
}
.form-button-4:nth-child(2),
.form-button-4:nth-child(3){
  flex: 0 0 25%;
  margin-left: 4%;
}

.form-button-4:nth-child(4){
  flex: 0 0 15%;
  margin-left: 4%;
}


.form-button--fixed-width-small{
  flex: 0 0 5vmin;
}

.form-footer-margin{
  margin: 1vh 5vw !important;
}

.form--button-3{
  flex: 0 0 30%;
}

.form--button-hidden{
  display: none;
}