@media only screen and (orientation: portrait) {
  .tool-bar {
    position: fixed;
    top: 100%;
    transform: translate(0,-100%);
    width: 100%;
    margin: 0;
  }
  .main-area{
    position: fixed;
    top: 0;
    height: 94vh;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
@media only screen and (orientation: landscape) {
  .tool-bar{
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0;
  }
  .main-area{
    position: fixed;
    top: 5vh;
    height: 95vh;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
}