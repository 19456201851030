.kv-color--green{
  color: #0F9960;
}

.kv-color--red{
  color: #DB3737;
}

.kv {
  max-width: 96vw;
  margin: 0.5vmin;
}

.kv--inline {
  overflow-x: hidden;
  display: inline-block;
  text-overflow: ellipsis}

.kv-block{
  overflow-x: hidden;
  text-overflow: ellipsis
}
