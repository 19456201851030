.instruments-container{
  width: 90vw;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.instruments-container-top{
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.instruments-content{
  overflow-x: hidden;
  overflow-y: auto;
}
.instruments-tool-button-container{
  margin-top: 0.5vh;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
}
.instrument-header{
  margin: auto;
}
.instruments-window{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
@media only screen and (orientation: portrait) {
  .instruments-content-x{
    max-height: 53vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .instruments-tool-button{
    flex: 1 1;
    margin: 0.5vmin auto;
  }
  .instruments-tool-button--5{
    flex: 0 0 100%;
    margin: 0.5vmin auto;
    width: 70%;
  }
}
@media only screen and (orientation: landscape) {
  .instruments-content-x{
    max-height: 43vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .instruments-tool-button{
    flex: 1 1;
    margin: auto 0.5vmin;
  }
  .instruments-tool-button--5{
    flex: 0 0 19%;
    margin: 0.5vmin auto;
  }

  .instruments-tool-button--4{
    flex: 0 0 24%;
    margin: 0.5vmin ;
  }
}

.instruments-cards--space{
  margin: 1vmin 0;
}
.instruments-progressbar--space{
  width: 80%;
  margin: 3vmin auto;
}
.instruments-info-element{
  margin: 1vmin auto;
  width: 80%;
  flex: 0 0 40%;
}
.instruments-info-bar{
    margin: auto;
}
.instruments-progressbar--container{
  width: 100vw;
  height: 20vmin;
  margin: auto;
  position: fixed;
  display: flex;
  flex-flow: column;
  padding: 1vmin;
  z-index: 99;
}
.position-middle{
  top: 50vh;
  left: 0;
  transform: translate(0, -50%);
}
.position-top{
  top: 0;
  left: 0;
}
.instrument-portal{
  width: 100vw;
  height: 80vmin;
  position: fixed;
  top: 20vmin;
  z-index: 20;
}

.instrument-process--status{
  position: fixed;
  left: 50vw;
  top: 97vh;
  transform: translate(-50%,-100%);
}
.instruments-card{
  margin: 1vmin 0;
  display: grid;
  grid-auto-flow: row dense;
}
.instruments-text{
  word-break: break-word;
}
.instruments--role-based-panel{
  margin: 1vmin auto;
  max-width: 98vw;
}

.card--title-icon{
 position: relative;
  left: 50%;
  transform: translate(-50%,-25%);
}
.instrument-button{
  margin-right: 1vmin;
}

.instruments--nav-bar{
  width: 100%;
  top: 0;
  transform: translate(0,50%);
}