.tiles-container {
  display: flex ;
  flex-flow: row wrap;
  justify-content: space-around;
}
.tiles-container-fixed {
  display: flex ;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.tiles-container-scroll {
  display: flex ;
  flex-flow: row wrap;
  justify-content: space-around;
  min-height: 0;
}
.tiles-container-vertical{
  display: flex ;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow-x: hidden;
}
.tiles-container-space{
  display: flex ;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
}
.tiles-item-flex{
  flex: 1 1;
}

.tiles-item-last{
  flex: 0 0 60%;
  margin: 0.25% 0 0.25% 0;
}

.tiles-item-row{
   flex: 0 0 99%;
   margin: 0.25%;
 }
.tiles-item-full-row{
  flex: 0 0 100%;
  margin-bottom: 0.5%;
}
.tiles-item-small{
  flex: 0 0 9%;
  margin: 0.125%;
}
.tiles-fixed-half{
  flex: 0 0 49.5%;
}
.tiles-container-fixed{
  display: flex ;
  flex-flow: row nowrap;
  justify-content: space-between;
}
@media only screen and (orientation: landscape) {
  .tiles-container-adjustable{
    display: flex ;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: center;
  }
  .tiles-container-between{
    display: flex ;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .tiles-container-start{
    display: flex ;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }
  .tiles-one-third{
    flex: 0 0 32%;
    margin: 0.25%;
  }
  .tiles-item{
    flex: 0 0 24%;
    margin: 0.25%;
  }
  .tiles-rom{
    flex: 0 0 35%;
    margin: 0.25%;
  }
  .tiles-half{
    flex: 0 0 49%;
    margin: 0.25%;
  }

  .tiles-half-sparse{
    flex: 0 0 30%;
    margin: 0.5% 10%;
  }

  .tiles-half-no-margin{
    flex: 0 0 49%;
  }

  .tiles-primary{
    flex: 0 0 90%;
    margin: 0.1%;
  }

  .tiles-item-sizable{
    flex: 0 0 19%;
    margin: 0.25%;
  }

  .tiles-secondary{
    flex: 0 0 9%;
    margin: 0.1%;
  }

  .tiles-item-tiny{
    flex: 0 0 5%;
    margin: 0.1%;
  }
}

@media only screen and (orientation: portrait ) {
  .tiles-secondary,
  .tiles-item,
  .tiles-item-tiny,
  .tiles-item-sizable,
  .tiles-one-third{
    flex: 1 1 99%;
    margin: 0.25%;
  }
  .tiles-half{
    flex: 1 1 99%;
    margin: 0.25%;
  }
  .tiles-half-sparse{
    flex: 0 0 90%;
    margin: 0.5%;
  }
  .tiles-container-adjustable{
    display: flex ;
    flex-flow: column wrap;
    justify-content: space-around;
    align-content: center;
  }
  .tiles-container-between{
    display: flex ;
    flex-flow: column wrap;
    justify-content: space-between;
  }
  .tiles-container-start{
    display: flex ;
    flex-flow: column wrap;
    justify-content: flex-start;
  }
  .tiles-half-no-margin{
    flex: 0 0 49%;
  }

  .tiles-primary{
    flex: 1 1 99%;
    margin: 0.25%;
  }
  .tiles-rom{
    flex: 1 1 99%;
    margin: 0.25%;
  }
}